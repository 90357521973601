<template>
  <div class="container scrolled">
    <div class="alert alert-secondary text-center mt-3 mb-1" v-if="canUpload">
      <Filestack />
    </div>

    <div class="row pb-3 pt-3" v-if="show_pictures">
      <div class="col-12 text-center" v-if="!hasPictures">
        <div class="alert alert-warning m-2 text-center">
          You have not yet uploaded any pictures!
        </div>
      </div>
      <div class="col-4 mb-3" v-for="pic in getPictures" :key="pic.ID">
        <span class="card" :class="{ active: isSelected(pic.ID) }">
          <span class="delete" @click="deletePicture(pic)"> &#x2716; </span>
          <span class="setpic" @click="selectPicture(pic)"> &#x2714; </span>
          <img :src="pic.guid" class="img-fluid" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Filestack from "./Filestack.vue";

export default {
  components: { Filestack },
  data() {
    return {
      selected_picture: "",
      show_pictures: true,
    };
  },
  props: {},
  computed: {
    ...mapGetters(["getPictures", "getDefaults"]),
    hasPictures() {
      return this.getPictures.length > 0;
    },
    canUpload() {
      return (
        this.getDefaults.image_upload_count <
        this.getDefaults.image_upload_limit
      );
    },
  },
  methods: {
    deletePicture(picture) {
      if (confirm("Are you sure?")) {
        this.$store.dispatch("deletePicture", [
          picture.ID,
          ({ message }) => {
            this.$toast.open({
              message,
              position: "bottom-left",
              type: "error",
            });
          },
        ]);
      }
    },
    selectPicture(picture) {
      if (picture.ID == this.selected_picture) return;
      this.selected_picture = picture.ID;
      this.$emit("selected", picture);
    },
    isSelected(id) {
      return this.selected_picture == id;
    },
  },
  mounted() {
    if (this.getPictures.length == 0) {
      this.$store.dispatch("loadPictures", () => {});
    }
  },
};
</script>
<style scoped>
.scrolled {
  height: 600px;
  overflow-y: scroll;
}
.card {
  padding: 150px 0 0;
  overflow: hidden;
}
.card > img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  max-height: 150%;
  opacity: 1;
  transform: translateX(-50%) translateY(-50%);
  transition: 1s opacity;
}
.card:hover {
  border: 1px solid green;
  cursor: pointer;
}
.card.active,
.card.active:hover {
  border: 3px dashed red !important;
  cursor: auto !important;
  opacity: 0.5;
}

.card > span.delete {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  padding: 0px 1px;
  border-radius: 2px;
  background-color: red;
  cursor: pointer;
  display: none;
}
.card:hover > span.delete {
  display: block;
}
.card > span.setpic {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  padding: 0px 1px;
  border-radius: 2px;
  background-color: green;
  cursor: pointer;
  display: none;
}
.card:hover > span.setpic {
  display: block;
}
</style>