<template>
  <div class="container scrolled">
    <CloseModalButton @close="closeModal" />
    <PicturesListContainer @selected="triggerSelected" />
  </div>
</template>

<script>
import CloseModalButton from "./CloseModalButton.vue";
import PicturesListContainer from "./PicturesListContainer2.vue";

export default {
  components: { CloseModalButton, PicturesListContainer },
  data() {
    return {};
  },
  props: {
    images: {
      type: Object,
      default() {
        return {};
      },
    },
    metaKey: {
      type: String,
      default: "image1",
    },
  },
  computed: {},
  methods: {
    closeModal() {
      this.$modal.hide(`pictures-list`);
    },
    triggerSelected(picture) {
      this.images[this.metaKey] = picture.guid;
      this.saveChanges(this.metaKey, picture.guid);
      this.closeModal();
    },

    saveChanges(metaKey, content) {
      switch (metaKey) {
        default:
          this.$store.dispatch("updateUserMeta", [
            metaKey,
            content,
            ({ message }) => {
              this.$toast.open({
                message,
                position: "bottom-left",
                type: "success",
              });
            },
          ]);
          break;
      }
    },
  },
  mounted() {},
};
</script>
