<template>
  <div>
    <h4 class="mt-3">
      What were your favorite things at the age in the photo?
    </h4>

    <div class="card mt-3">
      <div class="card-body text-center">
        <Spinner size="small" v-if="loading_image1" />
        <template v-else>
          <div v-if="images.favorite_things_image1 !== ``">
            <img :src="images.favorite_things_image1" class="img-fluid" />
          </div>
        </template>
        <div>
          <a href="javascript:void(0);" @click="showPicturesList1">
            <span
              class="d-block mt-3"
              v-if="images.favorite_things_image1 !== ``"
              >Change Photo</span
            >
            <span v-else>Add a photo of yourself as a child.</span>
          </a>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div
        class="col-md-12 mt-3"
        v-for="[key, label] in attributes1"
        :key="key"
      >
        <label :for="`favorite-things-before-${key}`">{{ label }}</label>
        <InputFormContainer
          type="input"
          :meta-key="`favorite-things-before-${key}`"
          @save="saveChanges"
        />
      </div>
    </div>

    <h4 class="mt-3">What are your favorites now?</h4>

    <div class="card mt-3">
      <div class="card-body text-center">
        <Spinner size="small" v-if="loading_image2" />
        <template v-else>
          <div v-if="images.favorite_things_image2 !== ``">
            <img :src="images.favorite_things_image2" class="img-fluid" />
          </div>
        </template>
        <div>
          <a href="javascript:void(0);" @click="showPicturesList2">
            <span
              class="d-block mt-3"
              v-if="images.favorite_things_image2 !== ``"
              >Change Photo</span
            >
            <span v-else
              >How have things changed? Add a recent photo of yourself.</span
            >
          </a>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div
        class="col-md-12 mt-3"
        v-for="[key, label] in attributes1"
        :key="key"
      >
        <label :for="`favorite-things-now-${key}`">{{ label }}</label>
        <InputFormContainer
          type="input"
          :meta-key="`favorite-things-now-${key}`"
          @save="saveChanges"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputFormContainer from "../InputFormContainer.vue";
import PicturesListModal3 from "../PicturesListModal3.vue";
import Spinner from "../Spinner.vue";

export default {
  components: {
    InputFormContainer,
    Spinner,
  },
  data() {
    return {
      content: "",
      images: {
        favorite_things_image1: "",
        favorite_things_image2: "",
      },
      loading_image1: true,
      loading_image2: true,
    };
  },
  computed: {
    attributes1() {
      const attrs = {
        color: "Color",
        song: "Song",
        food: "Food",
        book: "Book",
        movie: "Movie",
        tv_show: "TV Show",
        game_or_sport: "Game or Sport",
        hobby: "Hobby",
        teacher: "Teacher",
        holiday: "Holiday",
        school_subject: "School Subject",
        season: "Season",
        quote_or_life_motto: "Quote or Life Motto",
        creative_project: "Creative Project",
        play_date_activities: "Play Date Activities",
        meaningful_object_like_a_toy: "Meaningful Object (like a toy)",
        gift_received: "Gift Received",
        outfit_dress_or_costume: "Outfit, Dress or Costume",
      };
      return Object.entries(attrs);
    },
  },
  methods: {
    saveChanges({ metaKey, content }) {
      switch (metaKey) {
        default:
          this.$store.dispatch("updateUserMeta", [
            metaKey,
            content,
            ({ message }) => {
              this.$toast.open({
                message,
                position: "bottom-left",
                type: "success",
              });
            },
          ]);
          break;
      }
    },

    showPicturesList1() {
      this.$modal.show(
        PicturesListModal3,
        {
          images: this.images,
          metaKey: "favorite_things_image1",
        },
        {
          name: `pictures-list`,
          width: "55%",
        }
      );
    },
    showPicturesList2() {
      this.$modal.show(
        PicturesListModal3,
        {
          images: this.images,
          metaKey: "favorite_things_image2",
        },
        {
          name: `pictures-list`,
          width: "55%",
        }
      );
    },
  },
  mounted() {
    this.$store.dispatch("getUserMeta", [
      "favorite_things_image1",
      ({ meta_value }) => {
        this.images.favorite_things_image1 = meta_value;
        this.loading_image1 = false;
      },
    ]);
    this.$store.dispatch("getUserMeta", [
      "favorite_things_image2",
      ({ meta_value }) => {
        this.images.favorite_things_image2 = meta_value;
        this.loading_image2 = false;
      },
    ]);
  },
};
</script>

<style scoped>
div .input-form {
  height: 500px;
}
</style>